.titlecard{
    background-image: url('../../assets/sponsorpage/titleframe.png');
    height:200px;
    width:200px;
    padding: 50px;
    background-size: cover;
    object-fit: contain;
}

@media screen and (max-width: 420px){
    .titlecard{
        height:150px;
        width:150px;
        padding: 30px;
    }
}