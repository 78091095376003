.landingpage {
    position: relative;
    max-height: 100vh;
    width: 100%;
    /* background: none; */
    /* background-image: linear-gradient(#09101A, #42495C); */
    background-color: transparent;
}

.landingpage::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 480px){
    .landingpage{
        max-height: 75vh;
    }
}