.prizespage{
    /* background-image: linear-gradient(#F6DEBA, #C99F79); */
    background-color: transparent;
    height:fit-content;
    width:100%;
}

.prizes_heading{
    font-family: futilepro;
    color: #EA6032;
    font-size: 100px;
    text-align: left;
    text-shadow: 8px 8px #0401B9;
    padding-left: 6vw;
    padding-top: 16vh;
}

.prize_container1{
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    /* align-items: center; */
    margin-right: 10%;
    margin-left: 10%;
    /* gap: 234px; */
    /* gap: 140px; */
    gap:9rem;
    /* max-width */
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    row-gap: 5rem;
}

/* .prize_container2{
    padding-top: 20vh;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;
    gap: 78px;
    gap:4.8rem;
} */

@media screen and (max-width:480px){
    .prizes_heading{
        font-size: 80px;
    }
    .prize_container1{
        margin-top: 10%;
        grid-template-columns: none;
        gap: 5rem;
        /* margin-left: 15%; */
        align-items: center;
    }
    .prizes_heading{
        font-size:80px;
        text-align: center;
        padding-left: 0vw;
    }
    /* .first{
        order: 1;
    }
    .second{
        order: 2;
    }
    .third{
        order:3;
    }
    :nth-last-child(1){
        order: 3;
    }
    :nth-last-child(2){
        order: 3;
    } */
}