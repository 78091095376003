.faqs {
    /* align-items: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: pixelar;
    width: 100%;
    /* min-width: 800px; */
    padding: 15px;
    /* background-image: linear-gradient(#F6DEBA,#C99F79); */
    /* background-image: linear-gradient(#42495C,#09101A); */
    background-color: transparent;
    color:#F2F252;
    font-weight: 500;
    text-shadow: 1px 1px #0401B9;
    font-size:24px;
}

.faqtitle{
    font-family: futilepro;
    color: #EA6032;
    padding-top: 16vh;
    font-size:100px;
    text-shadow: 8px 8px #0401B9;
    padding-left: 6vw;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 4vh;
}

.faqs .faq {
    font-size:24px;
    max-width: 70vw;
    margin: 15px;
    padding: 15px;
    background-image: linear-gradient(#1F8FBF,#8CBCD2 );
    border-radius: 10px;
    box-shadow: 3px 3px #0401B9;
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 28px;
    min-width: 68vw;
    padding-right: 80px;
    transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}
