.aboutuscard{
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #EA6032;
    border: solid 4px #1F8FBF;
    height:35vh;
    width:45vw;
    font-family: pixelar;
    color: #F6DEBA;
    font-size:24px;
    box-shadow: 5px 5px #0401B9;
    padding:20px 10px 20px 10px;
    overflow-y: auto;
}

.aboutuscardheading{
    font-family: futilepro;
    padding-bottom: 10px;
    font-size: 26px;
}
