/* 
#sky {
    z-index: -1;
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-image: linear-gradient(#42495C,#09101A);
} */

/* #shootingstars { */
    /* z-index: 0; */
    /* z-index: -1;
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%))
    rotate(120deg);
} */

/* .wish {
    z-index: -1;
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
}
   */
#sky {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
    top:0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(#42495C,#09101A);
}

#shootingstars {
    z-index: -1;
    margin: 0;
    padding: 0;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%))
    rotate(120deg);
}

.wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
}
