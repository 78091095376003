@font-face {
    font-family: futilepro;
    src: url('../../assets/fonts/FutilePro.ttf');
}

.footercomp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-image: url("../../assets/footer/footer1.png");
    width: 100%;
    background-color: #3798C3;
    /* height: 204px; */
    height: 12.75rem;
}

.social{
    padding-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    /* padding-right: 10rem; */
}

.email{
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #F2F252;
    font-weight: bolder;
    text-shadow: 2px 2px #0401B9;
    font-family: pixelar;
    font-size: 44px;
}

.email img{
    padding-right: 10px;
}

.social > h1{
    font-family: futilepro;
    font-size: 60px;
    color: #EA6032;
    margin-bottom: 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.contact > h1{
    font-family: futilepro;
    font-size: 60px;
    color: #EA6032;
    margin-bottom: 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.contact{
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    padding-right: 1vw;
}

.contact > h1{
    font-family: futilepro;
    font-size: 60px;
    color: #EA6032;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.contact > a{
    padding-top: 2vh;
}

.links{
    padding-top: 2vh;
    display: flex;
    gap: 19px;
    list-style-type: none;
}

@media only screen and (max-width: 500px) {
    .footercomp{
        overflow-x: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #3798C3;
        /* height: 204px; */
        height: 30rem;
        justify-content: center;
        align-items: center;
    }

    .social{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }    
}