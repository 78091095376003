@font-face {
    font-family: futilepro;
    src: url('../../assets/fonts/FutilePro.ttf');
}

.landingcontent {
    /* position: relative; */
    /* z-index: 200; */
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 18vh;
    /* background-color: transparent; */
}

.landingcontent::-webkit-scrollbar {
    display: none;
}

.content {
    width: 80vw;
    padding: 1vw 6vw 0vw 6vw;
    margin-bottom: 5vw;
    border-radius: 20px;
    border: solid 5px #1F8FBF;
}

.hackowasptitle {
    /* position: relative; */
    /* z-index: 900; */
    font-family: futilepro;
    color: #EA6032;
    font-size: 150px;
    text-shadow: 8px 8px #0401B9;
}

.animated_icons {
    z-index: 300;
    padding-top: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.animated_icons img {
    scale: 0.7;
    padding: 20px;
}

.animated_icons .icon1 {
    animation: mover 1s infinite alternate;
}

.animated_icons .icon2 {
    animation: mover2 3s infinite alternate;
}

.animated_icons .icon3 {
    animation: mover 0.3s infinite alternate;
}

.animated_icons .icon4 {
    animation: mover2 1s infinite alternate;
}

.animated_icons .icon5 {
    animation: mover .6s infinite alternate;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover2 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-30px);
    }
}

/* Media Queries */

@media screen and (max-width: 480px) {
    .landingcontent {
        justify-content: center;
    }
    
    .hackowasptitle {
        padding: 8vh 0vh 5vh 0vh;
        font-size: 60px;
        text-shadow: 4px 4px #0401B9;
    }
    
    .content {
        width: 90vw;
        padding: 0.1vw 3vw 0vw 3vw;
    }
    .animated_icons {
        padding: 5vh 0vh 5vh 0vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .animated_icons img {
        scale: 0.5;
        padding: 5px;
        height: 75px;
        width: 75px;
    }
}

@media screen and (max-width: 410px) {
    .landingcontent {
        justify-content: center;
    }
    
    .hackowasptitle {
        padding: 8vh 0vh 5vh 0vh;
        font-size: 55px;
    }
    
    .content {
        width: 90vw;
        padding: 0.1vw 3vw 0vw 3vw;
    }
    .animated_icons {
        padding: 5vh 0vh 5vh 0vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .animated_icons img {
        scale: 0.5;
        padding: 5px;
        height: 65px;
        width: 65px;
    }
}

@media screen and (max-width: 378px) {
    .landingcontent {
        justify-content: center;
    }
    
    .hackowasptitle {
        padding: 8vh 0vh 5vh 0vh;
        font-size: 50px;
    }
    
    .content {
        width: 90vw;
        padding: 0.1vw 3vw 0vw 3vw;
    }
    .animated_icons {
        padding: 5vh 0vh 5vh 0vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .animated_icons img {
        scale: 0.5;
        padding: 5px;
        height: 60px;
        width: 60px;
    }

    .devfoliobutton{
        width: 100%;
        display: flex;
        justify-content:center ;
        align-items: center;
    }
}

@media screen and (max-width: 338px) {
    .landingcontent {
        justify-content: center;
    }
    
    .hackowasptitle {
        padding: 8vh 0vh 5vh 0vh;
        font-size: 45px;
    }
    
    .content {
        width: 90vw;
        padding: 0.1vw 3vw 0vw 3vw;
    }
    .animated_icons {
        padding: 5vh 0vh 5vh 0vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .animated_icons img {
        scale: 0.5;
        padding: 5px;
        height: 60px;
        width: 60px;
    }
}
