@font-face {
  font-family: pixelar;
  src: url('./assets/fonts/PixelarRegular.ttf');
}


.App {
  /* position: relative; */
  text-align: center;
  /* height:100vh; */
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
